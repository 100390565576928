import { useContext } from "react";
import {
  CountryAutoComplete,
  useCountry,
  BasicModal,
  SingleSelect,
  ADMIN,
  OPERATOR,
  ACTIVE,
  TenantIdContext,
  useIntl,
  buttonStyle,
} from "@datwyler/mfe-shared-components";
import { Form, Input } from "antd";

interface Values {
  firstName?: string;
  lastName?: string;
}

// For creating and updating users
const ProfileDialog = (props) => {
  const {
    isProfileDialogOpen,
    handleCloseProfileDialog,
    isUpdateUser,
    user,
    updateUser,
    addUser,
    isOwnProfile, // user not allowed to update his/her own email and role
  } = props;
  const [form] = Form.useForm();
  const { data: countriesData } = useCountry();
  const { tenantId }: any = useContext(TenantIdContext);
  const intl = useIntl();

  const roleOptions = [
    {
      label: intl.formatMessage({ id: ADMIN }),
      value: ADMIN,
    },
    {
      label: intl.formatMessage({ id: OPERATOR }),
      value: OPERATOR,
    },
  ];

  const handleSubmit = (values: Values) => {
    const submitValues = transformBeforeSubmit(values);

    if (isUpdateUser) {
      updateUser({
        variables: { input: submitValues },
      });
    } else {
      addUser({
        variables: { input: submitValues },
      });
    }
  };

  const transformBeforeSubmit = (submitValues) => {
    if (user?.id) {
      // update
      submitValues.id = user.id;
      submitValues.tenant = { id: user.tenant?.id };
      if (!submitValues.email) submitValues.email = user.email;
      if (!submitValues.role) submitValues.role = user.role;
      submitValues.status = user.status;
    } else {
      submitValues.status = ACTIVE;
      submitValues.tenant = { id: tenantId };
    }

    return submitValues;
  };

  return (
    <BasicModal
      title={
        isUpdateUser
          ? intl.formatMessage({ id: "update_profile_info" })
          : intl.formatMessage({ id: "add_user" })
      }
      isOpen={isProfileDialogOpen}
      handleCancel={handleCloseProfileDialog}
      handleOk={() => {}}
      okText={intl.formatMessage({ id: "save_changes" })}
      cancelText={intl.formatMessage({ id: "cancel" })}
      cancelButtonProps={{
        style: buttonStyle,
      }}
      okButtonProps={{
        type: "primary",
        style: {
          ...buttonStyle,
          fontSize: "16px",
        },
        htmlType: "submit",
        autoFocus: true,
      }}
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="profileForm"
          clearOnDestroy
          onFinish={(values) => handleSubmit(values)}
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            role: user?.role,
            company: user?.company,
            country: user?.country,
            contact: user?.contact,
          }}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        label={intl.formatMessage({ id: "first_name" })}
        name="firstName"
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "required_msg" }),
          },
        ]}
      >
        <Input
          size="large"
          placeholder={intl.formatMessage({ id: "enter_first_name" })}
        />
      </Form.Item>

      <Form.Item
        label={intl.formatMessage({ id: "last_name" })}
        name="lastName"
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "required_msg" }),
          },
        ]}
      >
        <Input
          size="large"
          placeholder={intl.formatMessage({ id: "enter_last_name" })}
        />
      </Form.Item>

      {!isOwnProfile && (
        <>
          <Form.Item
            label={intl.formatMessage({ id: "email" })}
            name="email"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required_msg" }),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={intl.formatMessage({ id: "enter_email" })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: "password" })}
            name="password"
          >
            <Input.Password
              size="large"
              placeholder={intl.formatMessage({ id: "enter_password" })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: "role" })}
            name="role"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required_msg" }),
              },
            ]}
          >
            <SingleSelect
              options={roleOptions}
              style={{ width: "100%", height: "57.6px" }}
              popupMatchSelectWidth={"100%"}
              placeholder={intl.formatMessage({ id: "select_role" })}
            />
          </Form.Item>
        </>
      )}

      <Form.Item label={intl.formatMessage({ id: "company" })} name="company">
        <Input
          size="large"
          placeholder={intl.formatMessage({ id: "enter_company" })}
        />
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: "country" })} name="country">
        <CountryAutoComplete
          countries={countriesData?.countries}
          initialValue={user?.country}
          onSelect={(val) => {
            form.setFieldValue("country", { id: val.id });
          }}
        />
      </Form.Item>

      <Form.Item
        label={intl.formatMessage({ id: "contact_number" })}
        name="contact"
      >
        <Input
          size="large"
          placeholder={intl.formatMessage({ id: "enter_phone_number" })}
        />
      </Form.Item>
    </BasicModal>
  );
};

export default ProfileDialog;
